import React, { Component } from "react";
import './styles.css'
import { Autocomplete, Box, Button, Checkbox, FormControlLabel, Grid, IconButton, LinearProgress, MenuItem, Modal, TextField } from "@mui/material";
import moment from "moment";
import StartFirebase from '../../configFirebase/index';
import { ref, set } from "firebase/database";
import { CheckCircle, Close } from "@mui/icons-material";

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

class RegistrasiPasien extends Component {
  state = {
    modalSubmit: false,
    loading: false,
    database: [],
    showFields: false,
    dataPasien: {},
    operasi: false,
    needLab: false,
    needRadiologi: false,
    tensi: '',
    nadi: '',
    respirasi: '',
    suhu: '',
    anamnesis: '',
    pemeriksaanFisik: '',
    hasilPenunjang: '',
    diagnosis: '',
    terapi: '',
    jenisPenyakit: '',
    PRB: false,
    alasanTidakPRB: ''
  }

  componentDidMount() {
    this.setState({ database: StartFirebase() });
  }

  _handleChangeNoRM = (e, val) => {
    this.setState({ dataPasien: val, showFields: !this.state.showFields });
  }

  _handleChangeValRM = (e) => {
    this.setState({ dataPasien: e.target.value });
  }

  _handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  _handleChangeOperasi = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  }

  _handleGoToList = () => {
    window.location.reload();
  }

  _handleCloseModal = (e) => {
    e.preventDefault();
    this.setState({ modalSubmit: false });
  }

  getAllData = () => {
    return {
      dataResume : {
        operasi: this.state.operasi,
        needLab: this.state.needLab,
        needRadiologi: this.state.needRadiologi,
        tensi: this.state.tensi,
        nadi: this.state.nadi,
        respirasi: this.state.respirasi,
        suhu: this.state.suhu,
        anamnesis: this.state.anamnesis,
        pemeriksaanFisik: this.state.pemeriksaanFisik,
        hasilPenunjang: this.state.hasilPenunjang,
        diagnosis: this.state.diagnosis,
        terapi: this.state.terapi,
        jenisPenyakit: this.state.jenisPenyakit,
        PRB: this.state.PRB,
        alasanTidakPRB: this.state.alasanTidakPRB
      },
      alamat: this.state.dataPasien.alamat,
      asuransi: this.state.dataPasien.asuransi,
      jamBerkunjung: this.state.dataPasien.jamBerkunjung,
      jenisKelamin: this.state.dataPasien.jenisKelamin,
      namaLengkap: this.state.dataPasien.namaLengkap,
      nik: this.state.dataPasien.nik,
      noAsuransi: this.state.dataPasien.noAsuransi,
      noRM: this.state.dataPasien.noRM,
      noRegistrasi: this.state.dataPasien.noRegistrasi,
      noRujukan: this.state.dataPasien.noRujukan,
      noTelp: this.state.dataPasien.noTelp,
      poli: this.state.dataPasien.poli,
      tempatLahir: this.state.dataPasien.tempatLahir,
      tglBerkunjung: this.state.dataPasien.tglBerkunjung,
      tglLahir: this.state.dataPasien.tglLahir,
      dokterPoli: this.state.dataPasien.dokterPoli,
      registrasiUlang: this.state.dataPasien.registrasiUlang,
      queueNumber: this.state.dataPasien.queueNumber,
      isInspection: true
    };
  }

  _handleSaveResume = (e) => {
    e.preventDefault();
    let db = this.state.database;
    let data = this.getAllData();
    set(ref(db, 'pasien/' + data.queueNumber), {
      queueNumber: data.queueNumber,
      alamat: data.alamat,
      asuransi: data.asuransi,
      jamBerkunjung: data.jamBerkunjung,
      jenisKelamin: data.jenisKelamin,
      namaLengkap: data.namaLengkap,
      nik: data.nik,
      noAsuransi: data.noAsuransi,
      noRM: data.noRM,
      noRegistrasi: data.noRegistrasi,
      noRujukan: data.noRujukan,
      noTelp: data.noTelp,
      poli: data.poli,
      tempatLahir: data.tempatLahir,
      tglBerkunjung: data.tglBerkunjung,
      tglLahir: data.tglLahir,
      dokterPoli: data.dokterPoli,
      registrasiUlang: data.registrasiUlang,
      dataResume: { ...data.dataResume },
      isInspection: data.isInspection
    });
    this.setState({ modalSubmit: true, loading: true },
      () => setTimeout(() => this.setState({ loading: false }), 500));
  }

  _renderModalSubmit = () => {
    return (
      <Modal open={this.state.modalSubmit}>
        <Box sx={styleModal}>
          {this.state.loading ?
            <Box sx={{ width: '100%' }}>
              <h3>Menyimpan Data...</h3>
              <LinearProgress />
            </Box> :
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <div style={{
                position: 'absolute',
                top: 0,
                right: 0,
              }}>
                <IconButton onClick={this._handleCloseModal}>
                  <Close />
                </IconButton>
              </div>
              <CheckCircle color='success' sx={{ fontSize: 150 }} />
              <h3>Penyimpanan data berhasil!</h3>
              <Button
                variant="contained"
                color="success"
                onClick={this._handleGoToList}
              >
                Lihat List Pasien
              </Button>
            </div>
          }
        </Box>
      </Modal>
    );
  }

  render() {
    const { showFields, dataPasien, tensi, anamnesis, diagnosis } = this.state;
    const { data } = this.props;
    let newData = (data || []).filter(val => val?.isInspection === undefined);
    return (
      <div className="containerContent">
        {this._renderModalSubmit()}
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Autocomplete
              className='wrappField'
              disablePortal
              id="combo-box-demo"
              onChange={this._handleChangeNoRM}
              options={newData}
              getOptionLabel={(option) => option && option.noRM}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label="No Rekam Medis"
                  onChange={this._handleChangeValRM}
                />
              }
            />
            {showFields &&
              <>
                <div>
                  <h3>Tanda Vital</h3>
                  <div style={{ display: 'flex' }}>
                    <TextField
                      fullWidth
                      error={tensi === ''}
                      className='wrappFieldSistolik'
                      label="Tekanan Darah"
                      name="tensi"
                      onChange={this._handleChange}
                    />
                    <TextField
                      fullWidth
                      className='wrappField'
                      label="Nadi"
                      name="nadi"
                      onChange={this._handleChange}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <TextField
                    fullWidth
                    className='wrappFieldSistolik'
                    label="Respirasi"
                    name="respirasi"
                    onChange={this._handleChange}
                  />
                  <TextField
                    fullWidth
                    className='wrappField'
                    label="Suhu"
                    name="suhu"
                    onChange={this._handleChange}
                  />
                </div>
                <TextField
                  fullWidth
                  className='wrappField'
                  label="Hasil Penunjang"
                  multiline
                  rows={4.3}
                  type='area'
                  name="hasilPenunjang"
                  onChange={this._handleChange}
                />
                <TextField
                  className='wrappField'
                  id="outlined-select-currency"
                  select
                  label="Jenis Penyakit"
                  fullWidth
                  name='jenisPenyakit'
                  onChange={this._handleChange}
                >
                  {['AKUT', 'KRONIS'].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </>
            }
          </Grid>
          <Grid item xs={4}>
            {showFields &&
              <>
                <TextField
                  disabled
                  fullWidth
                  className='wrappField'
                  label="Nama Lengkap"
                  value={dataPasien?.namaLengkap}
                />
                <div style={{
                  marginTop: '-4px', marginBottom: '6px'
                }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Pre-Operasi"
                    onChange={this._handleChangeOperasi}
                    name="operasi"
                  />
                </div>
                <TextField
                  fullWidth
                  error={anamnesis === ''}
                  className='wrappField'
                  label="Anamnesis"
                  multiline
                  rows={4.3}
                  type='area'
                  name="anamnesis"
                  onChange={this._handleChange}
                />
                <TextField
                  fullWidth
                  error={diagnosis === ''}
                  className='wrappField'
                  label="Diagnosis"
                  multiline
                  rows={4.3}
                  type='area'
                  name="diagnosis"
                  onChange={this._handleChange}
                />
                <TextField
                  className='wrappField'
                  id="outlined-select-currency"
                  select
                  label="Termasuk Diagnosis PRB?"
                  fullWidth
                  name='PRB'
                  onChange={this._handleChange}
                >
                  {['YA', 'TIDAK'].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </>
            }
          </Grid>
          <Grid item xs={4}>
            {showFields &&
              <>
                <TextField
                  disabled
                  fullWidth
                  className='wrappField'
                  label="Tanggal Lahir"
                  value={moment(dataPasien?.tglLahir).format('DD-MM-YYYY')}
                />
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: '-4px',
                  marginBottom: '6px'
                }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Konsul Lab"
                    onChange={this._handleChangeOperasi}
                    name="needLab"
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Konsul Radiologi"
                    onChange={this._handleChangeOperasi}
                    name="needRadiologi"
                  />
                </div>
                <TextField
                  fullWidth
                  className='wrappField'
                  label="Pemeriksaan Fisik"
                  multiline
                  rows={4.3}
                  type='area'
                  name="pemeriksaanFisik"
                  onChange={this._handleChange}
                />
                <TextField
                  fullWidth
                  className='wrappField'
                  label="Terapi/ Tindakan"
                  multiline
                  rows={4.3}
                  type='area'
                  name="terapi"
                  onChange={this._handleChange}
                />
                <TextField
                  fullWidth
                  className='wrappField'
                  label="Alasan Tidak Dilakukan PRB"
                  name="alasanTidakPRB"
                  onChange={this._handleChange}
                />
              </>
            }
          </Grid>
          {showFields &&
            <Button
              disabled={
                tensi === '' || anamnesis === '' ||
                diagnosis === '' ? true : false
              }
              fullWidth
              variant='contained'
              onClick={this._handleSaveResume}
            >
              SIMPAN DATA
            </Button>
          }
        </Grid>
      </div>
    )
  }
}

export default RegistrasiPasien;
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Description, Logout, PeopleAlt } from '@mui/icons-material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import './styles.css';

const pages = [
  { title: 'List Pasien', icon: <PeopleAlt /> },
  { title: 'Resume Medis', icon: <Description /> },
];

function ResponsiveAppBar(props) {
  const [titleRoute, setTitleRoute] = React.useState(0);

  const handleMenu = (title, idx) => () => {
    props.route(title);
    setTitleRoute(idx);
  }

  const handleLogout = () => {
    localStorage.removeItem('login');
    localStorage.removeItem('poliklinik');
    localStorage.removeItem('unit');
    localStorage.removeItem('username');
    window.location.reload();
  }

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Toolbar disableGutters>
              <Typography
                variant="h6"
                noWrap
                component="a"
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                POLIKLINIK {props?.loginAs?.toUpperCase()}
              </Typography>
            </Toolbar>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
            {pages.map((val, idx) => (
              <div className={titleRoute === idx ? 'wrapperMenuListActive' : 'wrapperMenuList'} onClick={handleMenu(val?.title, idx)} style={{ marginRight: 20 }}>
                <span style={{ marginRight: 5 }}>{val?.icon}</span> {val?.title}
              </div>
            ))}
            <div style={{ borderLeft: '3px solid #FFF', height: '35px', marginRight: 20 }}/>
            <Tooltip title="Logout">
              <IconButton onClick={handleLogout}>
                <Logout style={{ color: '#FFF' }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
import React, { Component } from 'react';
import './styles.css';
import { Alert, AlertTitle, Button, IconButton, MenuItem, Stack, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import imgLogin from '../../assets/imgLogin.jpg';
import Register from '../Register';
import { onValue, ref } from 'firebase/database';
import StartFirebaseUser from '../../configUserFirebase/index';

const db = StartFirebaseUser();

class Login extends Component {
  state = {
    username: '',
    password: '',
    showpass: false,
    errorLogin: false,
    successLogin: false,
    register: false,
    poli: '',
    dataTable: []
  }

  componentDidMount() {
    const dbRef = ref(db, 'user');
    onValue(dbRef, (snapshot) => {
      let records = [];
      snapshot.forEach(childSnapshot => {
        let data = childSnapshot.val();
        records.push(data);
      });
      this.setState({ dataTable: records })
    });
  }

  _handleChangeLogin = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  _handleLogin = () => {
    const { username, password, poli, dataTable } = this.state;
    const checkUser = dataTable?.filter(val =>
      val?.username === username && val?.password === password &&
      val?.poli === poli && val?.unit === 'poliklinik')?.length === 1;
    if (checkUser) {
      localStorage.setItem('login', true);
      localStorage.setItem('unit', 'poliklinik');
      localStorage.setItem('poliklinik', poli);
      localStorage.setItem('username', username);
      this.setState({ successLogin: true }, () => {
        setTimeout(() => window.location.reload(), 3000)
      });
    } else {
      this.setState({ errorLogin: true }, () => {
        setTimeout(() => this.setState({ errorLogin: false }), 3000)
      });
    }
  }

  _handleShowPass = () => {
    this.setState({ showpass: !this.state.showpass });
  }

  _renderAlertSuccess = () => {
    return (
      <Stack sx={{ position: 'absolute', top: 10 }} spacing={2}>
        <Alert severity="success">
          <AlertTitle>Login Berhasil!</AlertTitle>
          Anda akan dialihkan ke halaman Pasien Poliklinik
        </Alert>
      </Stack>
    );
  }

  _renderAlertError = () => {
    return (
      <Stack sx={{ position: 'absolute', top: 10 }} spacing={2}>
        <Alert severity="error">
          <AlertTitle>Login Gagal!</AlertTitle>
          Cek kembali Username dan Password yang Anda masukkan.
        </Alert>
      </Stack>
    );
  }

  _handleChange = (e) => {
    this.setState({ poli: e.target.value });
  }

  _handleRegister = () => {
    this.setState({ register: !this.state.register });
  }

  _handleBack = (val) => {
    this.setState({ register: val })
  }

  render() {
    const { username, password, errorLogin, successLogin, showpass, poli, register } = this.state;
    if (register) {
      return <Register onBack={this._handleBack} />
    } else {
      return (
        <div className='container'>
          {successLogin && this._renderAlertSuccess()}
          {errorLogin && this._renderAlertError()}
          <div className='wrapp-form'>
            <h2>SELAMAT DATANG</h2>
            <p>Silahkan Login terlebih dahulu untuk masuk ke Poliklinik Rawat Jalan</p>
            <h3 style={{ marginTop: 30 }}>Pilih Poliklinik</h3>
            <TextField
              className='wrappField'
              id="outlined-select-currency"
              select
              label="Poliklinik"
              fullWidth
              name='poli'
              onChange={this._handleChange}
            >
              {['Obgyn', 'Bedah', 'Penyakit Dalam', 'Jantung', 'Urologi'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <div className='wrapp-fields'>
              <TextField
                fullWidth
                label="Username"
                name='username'
                onChange={this._handleChangeLogin}
                value={username}
                sx={{ marginBottom: '10px' }}
              />
              <div style={{ position: 'relative' }}>
                <TextField
                fullWidth
                label="Password"
                  type={showpass ? 'text' : 'password'}
                  name='password'
                  onChange={this._handleChangeLogin}
                  value={password}
                />
                {showpass ?
                  <IconButton
                    onClick={this._handleShowPass}
                    style={{ position: 'absolute', right: 5, top: 4, zIndex: 2 }}
                  >
                    <VisibilityOff style={{ fontSize: '30px', color: 'grey' }} />
                  </IconButton> :
                  <IconButton onClick={this._handleShowPass} style={{ position: 'absolute', right: 5, top: 4, zIndex: 2 }}>
                    <Visibility style={{ fontSize: '30px', color: 'grey' }} />
                  </IconButton>
                }
              </div>
              <div style={{ display: 'flex', fontSize: '14px', justifyContent: 'end', marginTop: '10px' }}>
                  <div>Belum punya akun?</div>
                  <div className="daftar" onClick={this._handleRegister}>Daftar disini</div>
                </div>
              <Button
                disabled={
                  poli === '' ||
                    username === '' ||
                    password === '' ? true : false
                }
                fullWidth
                variant='contained'
                onClick={this._handleLogin}
                style={{ marginTop: 20 }}
              >
                Login
              </Button>
            </div>
          </div>
          <div>
            <img alt='' src={imgLogin} style={{ height: '100vh', paddingLeft: 50 }} />
          </div>
        </div>
      );   
    }
  }
}

export default Login;